import React from 'react'

import { Layout } from '@components/Layout'
import { SEO } from '@components/SEO'
import { Content } from '../screens/Cookies'

const CookiesPolicies = () => {

  return (

    <Layout>
      <SEO title="Cookies Policies" />
      <Content />
    </Layout>

  )
}

export default CookiesPolicies
